<template>
  <div class="wrapp-account account-navigation sidebar-block-panel">
    <div class="sidebar-block-content" v-if="currentUser">
      <div class="sidebar-block-content__body">
        <ul class="pages-link-list">
          <li class="page-link-item" v-for="route in getAccountRoutes(this.$router.options.routes)" :key="route.path">
            <i v-if="route.meta.menuIconName" :class="route.meta.menuIconName"></i>
            <router-link :to="{ name: route.name }" v-html="getRoutePathName(route)" class="btn btn-none bcm-link"></router-link>
          </li>
          <li class="page-link-item">
            <i class="icomoon-icon icon-logout"></i>
            <a class="btn btn-none bcm-link" @click.prevent="logout">
              {{$t('logout')}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { each, kebabCase } from 'lodash'
import { EventBus } from '@/helpers'

export default {
  computed: {
    ...mapState({
      currentUser: state => state.user.current
    })
  },
  methods: {
    logout () {
      this.$router.push({ name: 'home' })
      this.$store.commit('ui/setRightActiveComponent', {})
      this.$store.dispatch('user/logout')
      EventBus.$emit('track:loginStatus')
      location.reload()
    },
    getAccountRoutes (routes) {
      let allAccRoute = []
      const loop = (routes) => {
        each(routes, (item) => {
          // with "accountMenu" set to true, display the item in the account menu
          if (item.meta && item.meta.accountPage && item.meta.accountMenu) {
            allAccRoute.push(item)
          }
          if (item.children) {
            loop(item.children)
          }
        })
      }
      loop(routes)
      return allAccRoute
    },
    getRoutePathName (route) {
      // apply the kebabCase into name of the route for return the translated route path
      return this.$t(kebabCase(route.name))
    }
  }
}
</script>
